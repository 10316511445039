import React, { useEffect } from "react";
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from 'react-redux';
import Img from "gatsby-image/withIEPolyfill"
import { useDispatch } from 'react-redux';
import { motion, useSpring, useTransform, useMotionValue, useViewportScroll } from 'framer-motion';
import { isBrowser, isMobile } from "react-device-detect";
import FooterLayout from "../components/footer/footer";
import { WritingObserver } from "../components/animations/writing";
import BackgroundImage from 'gatsby-background-image'
import ReactPlayer from 'react-player'
import videoStratégie from '../images/content/agence/strategies_inclusives.mp4'
import videoCreative from '../images/content/agence/narrations_creatives.mp4'
import videoProduction from '../images/content/agence/productions_eclairees.mp4'
import Linkwrapper from "../components/linkWrapper/linkWrapper"
import { AppearAnimText } from "../components/animations/text_fade";
import { IntersectionObserver } from "../components/layout/intersection";
import Smoothscroll from "../components/Smoothscroll/smoothscroll";
import Link from "gatsby-link" // not error 

const animationfromleft = {
    hidden: {
        opacity: 0, x: -70,
        transition: {
            type: 'tween',
            duration: 0.5,
        }
    },
    show: {
        opacity: 1, x: 0,
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 60,
            duration: 0.5,
            staggerChildren: 0.35
        }
    },
    exit: {
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 0.5,
        }
    },
}

const animationfromright = {
    hidden: {
        opacity: 0, x: 70,
        transition: {
            type: 'tween',
            duration: 0.5,
        }
    },
    show: {
        opacity: 1, x: 0,
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 60,
            duration: 0.5,
            staggerChildren: 0.35
        }
    },
    exit: {
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 0.5,
        }
    },
}

const animation = {
    hidden: {
        opacity: 0, y: 70,
        transition: {
            type: 'tween',
            duration: 0.5,
        }
    },
    show: {
        opacity: 1, y: 0,
        transition: {
            type: 'spring',
            damping: 10,
            stiffness: 60,
            duration: 0.5,
            staggerChildren: 0.35
        }
    }
}


const animationImage = {
    hidden: {
        opacity: 0,
        y: 70,
        transition: {
            type: 'tween',
            duration: 0.65,
        }
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            damping: 30,
            stiffness: 40,
            duration: 0.35,
        }
    }
}

function AgencePage({ data }) {

    const { scrollYProgress } = useViewportScroll();

    return (
        <>
            <SEO title="l'agence" />
            <div>
                {isBrowser && <ParallaxForms scrollYProgress={scrollYProgress} data={data} />}
            </div>
            <Smoothscroll
                className="flex justify-center flex-col items-center w-full border-b-2 z-20">
                <Header />
                <StrategieSection data={data} />
                <CreativeSection data={data} />
                <ProductionSection data={data} />
                <MaisonSection data={data} />
                <DirectionSection data={data} />
                <InstaLifeSection data={data} />
                <NosHistoiresSection data={data} />
                <FooterLayout />
            </Smoothscroll>
        </>
    )
}

const Header = () => {
    return (
        <>
            <div className="flex lg:block w-full lg:w-3/4 items-center justify-center h-screen">
                <div className="lg:pt-32 flex flex-col justify-center items-center">
                    <motion.p className="px-10 lg:px-0 relative font-Gilroy_ExtraBoldItalic text-3xl lg:text-7xl text-center text-black leading-none z-20"
                        initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
                        transition={{
                            type: 'spring',
                            damping: 15,
                            stiffness: 60,
                            duration: 2.5,
                            delay: 0.3,
                            staggerChildren: 0.35
                        }}
                    >Plus que des storytellers,<br></br>
     nous sommes des</motion.p>
                    <div className="-mt-6 lg:-mt-16 w-3/4"><WritingObserver text="storymakers" delay="500" /></div>
                    <div className="">
                        <motion.p className="relative px-10 lg:px-0 justify-center text-center align-middle font-Gilroy_ExtraBoldItalic mt-6 lg:mt-16 text-xl lg:text-2xl text-black"
                            initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
                            transition={{
                                type: 'spring',
                                damping: 15,
                                stiffness: 60,
                                duration: 1.5,
                                delay: 0.5,
                                staggerChildren: 0.35
                            }}
                        >
                            Notre fil rouge, dérouler le vôtre et transformer<br></br>
les expériences de marque en émotions.
       </motion.p>
                    </div>
                </div>
            </div>
        </>
    )
}

const StrategieSection = ({ data }) => {

    return (
        <>
            <section className="w-full flex flex-col lg:h-screen items-center justify-center lg:flex-row">
                <div className="w-full h-screen-1/2 lg:h-full lg:w-1/3 relative overflow-hidden">
                    {
                        isBrowser &&
                        <ReactPlayer
                            width='135%'
                            height='100%'
                            loop={true}
                            playing={true}
                            controls={false}
                            muted={true}
                            url={videoStratégie} >
                        </ReactPlayer>
                    }
                    {
                        isMobile &&
                        <BackgroundImage
                            Tag="section"
                            className="w-full h-full top-0 z-0 absolute"
                            fluid={data.strategieImg.childImageSharp.fluid}
                            alt="stratégies"
                            backgroundColor={`#136F63`}
                        />
                    }
                </div>
                <div className="flex flex-col lg:flex-row relative py-0 lg:py-20 justify-start items-center w-full lg:w-2/3 text-gray-800 text-right">
                    <div className="w-full p-10 lg:p-0 lg:w-1/2 text-right lg:absolute left-0 lg:-ml-12 xl:-ml-24 xxl:-ml-32 flex flex-col">
                        <h1 className="font-Gilroy_ExtraBoldItalic stroke text-5xl lg:text-5xl xl:text-8xl leading-none break-normal">Des stratégies</h1>
                        <div className="-mt-4 lg:-mt-8 -mr-8"><WritingObserver isReset={true} text="inclusives" /></div>
                        <div className="mt-10 lg:mt-20 font-Gilroy_medium text-gray-800 text-base">
                            #Benchmark #Plateforme de marque<br></br>
                            #UX #Identité #Naming
                    </div>
                    </div>
                    <div className="w-full p-10 lg:p-0 lg:w-1/2 lg:absolute right-0">
                        <IntersectionObserver isReset={true}>
                            <AppearAnimText>
                                <motion.p variants={animationfromright} className="font-Gilroy_extrabold uppercase text-sm tracking-widest2 pb-8 lg:pb-12">Conseil</motion.p>
                                <motion.div variants={animation} className="text-base lg:text-base">
                                    <p className=" font-Gilroy_medium tracking-wider">Nous n’avons pas d’idées préconçues.</p>
                                    <p className="font-Gilroy_medium tracking-wider">
                                        <b className="text-black">Vous connaître, vous, votre marché, vos
                                        cibles, le monde qui les entoure, leurs
attentes et leurs usages</b> font partie
d’une <b className="text-black">phase d’audit essentielle</b> dans
notre démarche.
<br></br>
                                        <br></br>
                                        <b className="text-black">Ensemble, nous en dégageons des
insights puissants</b> pour développer des
plateformes de marque dans lesquelles
vous vous reconnaissez, des namings
qui vous rendent fiers et des stratégies
de communication percutantes.</p>
                                </motion.div>
                            </AppearAnimText>
                        </IntersectionObserver>
                    </div>
                </div>
                <div className="w-full lg:w-1/5"></div>
            </section>
        </>
    )
}

const CreativeSection = ({ data }) => {
    return (
        <>
            <section className="w-full relative flex flex-col-reverse lg:h-screen items-center overflow-hidden justify-center lg:flex-row">
                <div className="w-full lg:w-1/5"></div>
                <div className="flex flex-col-reverse lg:flex-row relative lg:py-20 justify-start items-center w-full lg:w-2/3 text-gray-800 text-left">
                    <div className="w-full p-10 lg:p-0 lg:w-1/2 lg:absolute left-0">
                        <IntersectionObserver isReset={true}>
                            <AppearAnimText>
                                <motion.p variants={animationfromleft} className="font-Gilroy_extrabold uppercase text-sm tracking-widest2 pb-8 lg:pb-12 ">Création</motion.p>
                                <motion.div variants={animation}>
                                    <p className="font-Gilroy_medium text-base lg:text-base tracking-wider">
                                        <b className="text-black">Nous sommes convaincus qu’une création
n’a de sens que si elle vous raconte.<br></br>
                                        </b> Elle est esthétique quand elle vous est utile
et efficace.<br></br><br></br>
                                        <b className="text-black">Chaque concept, chaque identité, chaque
                            discours est une création originale,</b> développée au service de vos objectifs.</p>
                                </motion.div>
                            </AppearAnimText>
                        </IntersectionObserver>
                    </div>
                    <div className="w-full p-10 lg:p-0 lg:w-1/2 text-left lg:absolute right-0 lg:-mr-32 flex flex-col">
                        <h1 className="font-Gilroy_ExtraBoldItalic stroke text-5xl lg:text-5xl xl:text-8xl leading-none break-normal">Des narrations</h1>
                        <div className="-mt-4 lg:-mt-8"><WritingObserver reset={true} text="creatives" /></div>
                        <div className="mt-10 lg:mt-20 font-Gilroy_medium text-gray-800 text-base">
                            #Illustration #BrandContent<br></br>
                        #UI #Logotype
                    </div>
                    </div>
                </div>
                <div className="w-full mt-10 lg:mt-0 h-screen-1/2 lg:h-auto lg:w-1/3 overflow-hidden">
                    {
                        isBrowser &&
                        <ReactPlayer
                            width='135%'
                            height='100%'
                            loop={true}
                            playing={true}
                            controls={false}
                            muted={true}
                            url={videoCreative} >
                        </ReactPlayer>
                    }
                    {
                        isMobile &&
                        <ReactPlayer
                            width='100%'
                            height='auto'
                            loop={true}
                            playing={true}
                            controls={false}
                            muted={true}
                            url={videoCreative} >
                        </ReactPlayer>
                    }
                </div>
            </section>
        </>
    )
}

const ProductionSection = ({ data }) => {
    return (
        <>
            <section className=" w-full flex flex-col lg:h-screen items-start justify-center lg:flex-row">
                <div className="overflow-hidden w-full mt-10 lg:mt-0 h-screen-1/2 lg:h-full lg:w-1/3 relative">
                    {
                        isBrowser &&
                        <ReactPlayer
                            width='130%'
                            height='100%'
                            loop={true}
                            playing={true}
                            controls={false}
                            muted={true}
                            url={videoProduction} >
                        </ReactPlayer>
                    }
                    {
                        isMobile &&
                        <ReactPlayer
                            width='100%'
                            height='auto'
                            loop={true}
                            playing={true}
                            controls={false}
                            muted={true}
                            url={videoProduction} >
                        </ReactPlayer>
                    }
                </div>
                <div className="flex relative h-full flex-col lg:pt-20 justify-start items-start w-full lg:w-2/3 text-gray-800 text-right">
                    <div className="flex flex-col lg:flex-row justify-between w-full relative">
                        <div className="w-full lg:w-2/3 p-10 lg:p-0 text-right  left-0 lg:-ml-40 xl:-ml-56 xxl:-ml-64 flex flex-col">
                            <h1 className="font-Gilroy_ExtraBoldItalic stroke text-5xl xl:text-8xl leading-none break-normal">Des productions</h1>
                            <div className="w-full -mt-4 lg:-mt-8"><WritingObserver reset={true} text="eclairees" /></div>
                            <div className="mt-10 lg:mt-20 font-Gilroy_medium text-gray-800 text-base">
                                #Print #Motion #Digital #Event
                    </div>
                        </div>
                        <div className="w-full p-10 lg:p-0 lg:w-1/2  right-0">
                            <IntersectionObserver isReset={true}>
                                <AppearAnimText>
                                    <motion.p variants={animationfromright} className="font-Gilroy_extrabold uppercase text-sm tracking-widest2 pb-8 lg:pb-12">360°</motion.p>
                                    <motion.div variants={animation}>
                                        <p className="font-Gilroy_medium text-base lg:text-base tracking-wider">
                                            Parce que <b className="text-black">c’est avec des détails
que l’on crée des expériences singulières, on ne laisse rien au hasard.</b><br></br><br></br> Un site qui va vous faire sourire, un grain de papier qui va vous émoustiller, un objet personnalisé qui va vous émerveiller, une scénographie qui va vous amuser, on pense à tout mais surtout à répondre à vos objectifs avec émotion. </p>
                                    </motion.div>
                                </AppearAnimText>
                            </IntersectionObserver>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/5"></div>
            </section>
        </>
    )
}



const MaisonSection = ({ data }) => {
    return (
        <>
            <section className="w-full flex flex-col-reverse mt-10 lg:mt-0 relative lg:h-screen items-center justify-center lg:flex-row">
                <div className="flex flex-col justify-center items-center w-full p-10 lg:p-0 h-full lg:w-2/4 lg:absolute z-10">
                    <h1 className="font-Gilroy_ExtraBoldItalic text-4xl lg:text-7xl lg:mt-16 text-center text-black leading-none z-10">Tactile,<br></br>maison d’idées et</h1>
                    <div className="-mt-4 lg:-mt-8"><WritingObserver reset={true} text="expressions" /></div>
                    <IntersectionObserver isReset={true}>
                        <AppearAnimText>
                            <motion.div variants={animation} className="mt-10 lg:mt-10 lg:px-10 font-Gilroy_ExtraBoldItalic text-xl lg:text-2xl  text-center  text-black z-10">
                                Nous vivons dans une maison avec des personnalités
                                aux parcours hétéroclites. Nos différences et la diversité
                                de nos expériences sont une force au quotidien
                                pour créer des liens et développer des synergies.
<br></br><br></br></motion.div>
                        </AppearAnimText>
                    </IntersectionObserver >
                </div>
                <div className="w-full h-screen-1/2 lg:h-full lg:w-1/4 lg:absolute right-0">
                    <BackgroundImage
                        Tag="section"
                        className="w-full h-full top-0 z-0 absolute"
                        fluid={data.maisonImg.childImageSharp.fluid}
                        alt="maison"
                        backgroundColor={`#136F63`}
                    />
                </div>
            </section>
        </>
    )
}

const DirectionSection = ({ data }) => {
    return (
        <>
            <section className="w-full flex items-center justify-center flex-col">
                <div className="w-full lg:w-3/4  relative mt-10 lg:mt-0 lg:pt-40">
                    <IntersectionObserver isReset={true}>
                        <AppearAnimText>
                            <motion.div variants={animationImage} >
                                <div className="w-full h-screen-1/2 lg:h-screen-3/4 right-0">
                                    <BackgroundImage
                                        Tag="section"
                                        style={{
                                            backgroundPosition: '45% 50%',
                                        }}
                                        className="w-full  h-full top-0 z-0"
                                        fluid={data.directionImg.childImageSharp.fluid}
                                        alt="direction"
                                        backgroundColor={`#136F63`}
                                    />
                                </div>
                            </motion.div>
                        </AppearAnimText>
                    </IntersectionObserver>
                </div>
                <div className="w-full flex lg:w-3/4 items-center justify-center flex-col p-10 lg:p-0">
                    <div className="flex flex-col lg:flex-row w-full lg:py-20">
                        <div className="pl-0 lg:pl-20 w-full lg:w-2/3 font-Gilroy_extrabold uppercase text-sm tracking-widest2 pb-8 lg:pb-0">
                            <IntersectionObserver isReset={true}>
                                <AppearAnimText>
                                    <motion.div variants={animation} className="pt-10 lg:pt-0">
                                        <div className="font-Gilroy_extrabold uppercase text-sm tracking-widest2 pb-5">Les fondateurs</div>
                                        <Linkwrapper className="inline-block text-2xl lg:text-2xl" mouseIcon="linkedin">
                                            <motion.div whileHover={{ scale: 1.1 }} className="font-Gilroy_ExtraBoldItalic text-black"><a href="https://www.linkedin.com/in/delphine-descorps-505b3310/" rel="noopener noreferrer" name="LinkedinDelphine" target="_blank">Delphine Descorps</a></motion.div>
                                            <motion.div whileHover={{ scale: 1.1 }} className="font-Gilroy_ExtraBoldItalic  text-black"><a href="https://www.linkedin.com/in/saig-de-qu%C3%A9netain-b80a4b31/" rel="noopener noreferrer" name="LinkedinSaig" target="_blank">Saig de Quénetain</a></motion.div>
                                        </Linkwrapper>
                                    </motion.div>
                                </AppearAnimText>
                            </IntersectionObserver>
                        </div>
                        <div className="flex flex-col w-full lg:w-2/3 pr-0 lg:pr-10 ">
                            <IntersectionObserver isReset={true}>
                                <AppearAnimText>
                                    <motion.div variants={animation} className="w-full">
                                        <p className="font-Gilroy_medium text-base lg:text-base tracking-wider">Tactile est le fruit d’une ambition commune,
celle de <b className="text-black">Delphine Descorps, Directrice conseil </b>
et de <b className="text-black">Saig de Quénetain, Directeur de création.</b><br></br>
Tous les deux, issus d’agences de pub et autres web agency, partagent la conviction que l’émotion est différenciante, marquante et personnelle. Ils fondent alors l'agence Tactile où chaque stratégie de communication élaborée, chaque dispositif est pensé en fonction de sa capacité à émouvoir pour une expérience durable et mémorable.
<br></br><br></br>
                                            <b className="text-black">10 ans après la création de Tactile, la ligne directrice n’a pas changé </b>
et les règles à  la maison non plus. Leurs 3 mots
d’ordre ? <br></br><br></br> <b className="text-black">Curiosité, humilité et créativité !</b></p>
                                    </motion.div>
                                </AppearAnimText>
                            </IntersectionObserver>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const InstaLifeSection = ({ data }) => {

    const Image = data.allMarkdownRemark.edges[0].node.frontmatter.instalife

    return (
        <>
            <section className="w-full flex items-center justify-center flex-col">
                <div className="flex flex-col w-full lg:w-3/4 mt-10 lg:mt-20">
                    <div className="flex p-10 lg:p-0 lg:pl-20 lg:pb-10 flex-col items-left justify-center w-full lg:w-1/2 tracking-wider">
                        <h1 className="pb-10 lg:pb-20 font-Gilroy_extrabold tracking-widest2 uppercase text-sm">#LifewithTactileisfantastic</h1>
                    </div>
                    <div className="flex items-center gridlayout justify-center flex-col lg:flex-row lg:flex-wrap lg:gap-20 lg:row-gap-20 mb-10">
                    {Image !== null &&
                        Image.map((image, index) => (
                            <div key={index} className="w-full lg:w-1/3">
                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                    <AppearAnimText>
                                        <div className="h-full">
                                            <motion.div variants={animationImage} className="w-full h-full">
                                                <Img
                                                    fluid={image.childImageSharp.fluid}
                                                    objectFit="cover"
                                                    objectPosition="50% 50%"
                                                    alt="instalife"
                                                />
                                            </motion.div>
                                        </div>
                                    </AppearAnimText>
                                </IntersectionObserver>
                            </div>
                        ))
                        }
                        <div className="flex px-10 lg:px-0 flex-col items-start justify-left w-full lg:w-1/3">
                            <div className="mt-20">
                                <h1 className="font-Gilroy_extrabold uppercase text-sm">suivez-nous</h1>
                                <Linkwrapper className="inline-block" mouseIcon="instagram">
                                    <motion.div whileHover={{ scale: 1.1 }} className="font-Gilroy_ExtraBoldItalic text-5xl  justify-center text-left">
                                        <a href="https://www.instagram.com/agence_tactile/" name="Instagram" rel="noopener noreferrer" target="_blank">InstaLife</a>
                                    </motion.div>
                                </Linkwrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* {post.node.frontmatter.agencekey !== null &&
                                    <>
                                            
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta3.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta5.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta7.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta9.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                            </div>
                                            <div className="flex-inline items-start px-0 lg:px-0 lg:-mt-80 flex-wrap flex-col gap-10 w-full lg:w-1/3">
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta2.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta4.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta6.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                                <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                                    <AppearAnimText>
                                                        <div className=" h-full mb-10 lg:mb-20">
                                                            <motion.div variants={animationImage} className="w-full h-full">
                                                                <Img
                                                                    fluid={post.node.frontmatter.insta8.childImageSharp.fluid}
                                                                    objectFit="cover"
                                                                    objectPosition="50% 50%"
                                                                    alt="instalife"
                                                                />
                                                                <div className="flex px-10 lg:px-0 flex-col items-center justify-left w-full">
                                                                    <div className="mt-20">
                                                                        <h1 className="font-Gilroy_extrabold uppercase text-sm">suivez-nous</h1>
                                                                        <Linkwrapper className="inline-block" mouseIcon="instagram">
                                                                            <motion.div whileHover={{ scale: 1.1 }} className="font-Gilroy_ExtraBoldItalic text-5xl  justify-center text-left">
                                                                                <a href="https://www.instagram.com/agence_tactile/" name="Instagram" rel="noopener noreferrer" target="_blank">InstaLife</a>
                                                                            </motion.div>
                                                                        </Linkwrapper>
                                                                    </div>
                                                                </div>
                                                            </motion.div>
                                                        </div>
                                                    </AppearAnimText>
                                                </IntersectionObserver>
                                            </div> */}
            {/* <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                        <AppearAnimText>
                                            <div className="flex w-full pointer-events-none lg:-mt-48 h-full justify-between gap-5 items-start flex-col lg:flex-row  px-0 lg:px-0">
                                                <motion.div variants={animationImage} className="w-full lg:w-1/2 ">
                                                    <Img
                                                        fluid={post.node.frontmatter.insta1.childImageSharp.fluid}
                                                        objectFit="cover"
                                                        objectPosition="50% 50%"
                                                        alt="instalife"
                                                    />
                                                </motion.div>
                                                {isMobile && <p className="w-full p-10 font-Gilroy_ExtraBoldItalic text-2xl justify-center text-left">« Multum erat profecto qui incidere forte eo solebat pauci qui. »</p>}
                                                <motion.div variants={animationImage} className="w-full lg:w-1/2 lg:-mt-40">
                                                    <Img
                                                        fluid={post.node.frontmatter.insta2.childImageSharp.fluid}
                                                        objectFit="cover"
                                                        objectPosition="50% 50%"
                                                        alt="instalife"
                                                    />
                                                </motion.div>
                                            </div>
                                        </AppearAnimText>
                                    </IntersectionObserver>
                                    <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                        <AppearAnimText>
                                            <div className="flex items-start w-full pointer-events-none h-full justify-between gap-5 flex-col lg:flex-row px-0 lg:px-0">
                                                <motion.div variants={animationImage} className="w-full lg:w-1/2 ">
                                                    <Img
                                                        fluid={post.node.frontmatter.insta3.childImageSharp.fluid}
                                                        objectFit="cover"
                                                        objectPosition="50% 50%"
                                                        alt="instalife"
                                                    />
                                                </motion.div>
                                                <motion.div variants={animationImage} className="w-full lg:w-1/2 lg:-mt-40">
                                                    <Img
                                                        fluid={post.node.frontmatter.insta4.childImageSharp.fluid}
                                                        objectFit="cover"
                                                        objectPosition="50% 50%"
                                                        alt="instalife"
                                                    />
                                                </motion.div>
                                            </div>
                                        </AppearAnimText>
                                    </IntersectionObserver>
                                    <IntersectionObserver isReset={true} thresholdParam={0.2}>
                                        <AppearAnimText>
                                            <div className="flex items-start w-full h-full justify-between gap-5 flex-col lg:flex-row px-0 lg:px-0">
                                                <motion.div variants={animationImage} className="w-full lg:w-1/2 ">
                                                    <Img
                                                        fluid={post.node.frontmatter.insta5.childImageSharp.fluid}
                                                        objectFit="cover"
                                                        objectPosition="50% 50%"
                                                        alt="instalife"
                                                    />
                                                </motion.div>
                                                {isMobile &&
                                                    <div className=" w-full px-10 py-10 font-Gilroy_ExtraBoldItalic text-2xl text-left">
                                                        « Multum erat profecto qui incidere forte eo solebat pauci qui. »
                                                         </div>
                                                }
                                                <motion.div variants={animationImage} className="w-full lg:w-1/2 lg:-mt-40">
                                                    <Img
                                                        fluid={post.node.frontmatter.insta6.childImageSharp.fluid}
                                                        objectFit="cover"
                                                        objectPosition="50% 50%"
                                                        alt="instalife"
                                                    />
                                                    <div className="flex px-10 lg:px-0 lg:ml-20 flex-col items-start justify-left w-full">
                                                        {isBrowser &&
                                                            <div className=" h-full w-3/4 font-Gilroy_ExtraBoldItalic text-2xl pt-20 text-left">
                                                                « Multum erat profecto qui incidere forte eo solebat pauci qui. »
                                                         </div>
                                                        }
                                                        <div className="mt-20">
                                                            <h1 className="font-Gilroy_extrabold uppercase text-sm">suivez-nous</h1>
                                                            <Linkwrapper className="inline-block" mouseIcon="instagram">
                                                                <motion.div whileHover={{ scale: 1.1 }} className="font-Gilroy_ExtraBoldItalic text-5xl  justify-center text-left">
                                                                    <a href="https://www.instagram.com/agence_tactile/" name="Instagram" rel="noopener noreferrer" target="_blank">InstaLife</a>
                                                                </motion.div>
                                                            </Linkwrapper>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            </div>
                                        </AppearAnimText>
                                    </IntersectionObserver> */}
        </>
    )
}


const NosHistoiresSection = ({ data }) => {
    return (
        <>
            <section className="w-full flex items-center justify-center my-20">
                <Link to="/projets/">
                    <Linkwrapper mouseIcon="discover">
                        <div className="flex flex-col text-center items-center">
                            <h1 className="font-Gilroy_ExtraBoldItalic stroke text-4xl lg:text-4xl xl:text-6xl leading-none break-normal">Découvrez<br></br>nos</h1>
                            <div className="w-3/4 -mt-3 lg:-mt-6"><WritingObserver reset={true} text="histoires_footer" /></div>
                        </div>
                    </Linkwrapper>
                </Link>
            </section>
        </>
    )
}

export default connect(state => ({
    scrollHeight: state.Scrollvalue.scrollHeight,
}), null)(AgencePage);


const ParallaxForms = ({ data, scrollYProgress }) => {

    const yvalue = useTransform(scrollYProgress, [0, 1], [0, -350]);
    const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2 })

    const yvalue2 = useTransform(scrollYProgress, [0, 1], [0, -300]);
    const yspring2 = useSpring(yvalue2, { damping: 20, stiffness: 5, mass: 0.2 })

    const yvalue3 = useTransform(scrollYProgress, [0, 1], [0, -250]);
    const yspring3 = useSpring(yvalue3, { damping: 20, stiffness: 5, mass: 0.2 })

    const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 100]);
    const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2 })

    return (
        <>
            <motion.div
                transition={{ duration: 0.1 }}
                style={{ y: isBrowser ? yspring : yspringMobile }}
                className="forme_opti fixed pointer-events-none top-0 mt-56 ml-16 left-0 w-20 z-0">
                <Img
                    fluid={data.form1.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="forme"
                />
            </motion.div>
            <motion.div
                transition={{ duration: 0.1 }}
                style={{ y: isBrowser ? yspring2 : yspringMobile }}
                className="forme_opti fixed pointer-events-none top-0 mt-56 ml-32 left-0 w-10 z-0">
                <Img
                    fluid={data.form2.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="forme"
                />
            </motion.div>
            <motion.div
                transition={{ duration: 0.1 }}
                style={{ y: isBrowser ? yspring2 : yspringMobile }}
                className="forme_opti fixed pointer-events-none top-0 ml-56 right-0 w-90 z-0">
                <Img
                    fluid={data.form3.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="forme"
                />
            </motion.div>
            <motion.div
                transition={{ duration: 0.1 }}
                style={{ y: isBrowser ? yspring : yspringMobile }}
                className="forme_opti fixed pointer-events-none top-0 pt-64 mt-64 mt- -mr-10 right-0 w-24 z-0">
                <Img
                    fluid={data.form4.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="forme"
                />
            </motion.div>
            <motion.div
                transition={{ duration: 0.1 }}
                style={{ y: isBrowser ? yspring3 : yspringMobile }}
                className="forme_opti fixed pointer-events-none top-0 mr-20 mt-40 right-0 w-16 z-0">
                <Img
                    fluid={data.form5.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="forme"
                />
            </motion.div>
            <motion.div
                transition={{ duration: 0.1 }}
                style={{ y: isBrowser ? yspring2 : yspringMobile }}
                className="forme_opti fixed pointer-events-none top-0 mr-56 mt-40 right-0 w-4 z-0">
                <Img
                    fluid={data.form6.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="forme"
                />
            </motion.div>
            <motion.div
                transition={{ duration: 0.1 }}
                style={{ y: isBrowser ? yspring : yspringMobile }}
                className="forme_opti fixed pointer-events-none top-0 mr-56 mt-56 right-0 w-6 z-0">
                <Img
                    fluid={data.form7.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="forme"
                />
            </motion.div>
        </>
    )
}

export const agenceQuery = graphql`
query pageagence {
    form1: file(relativePath: { eq: "UI/projets/blob_tags_1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       }
       form2: file(relativePath: { eq: "UI/projets/blob_tags_2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       }
       form3: file(relativePath: { eq: "UI/projets/blob_tags_3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       }
       form4: file(relativePath: { eq: "UI/projets/blob_tags_4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       }
       form5: file(relativePath: { eq: "UI/projets/blob_tags_5.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       }
       form6: file(relativePath: { eq: "UI/projets/blob_tags_6.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       }
       form7: file(relativePath: { eq: "UI/projets/blob_tags_7.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       }
       strategieImg: file(relativePath: { eq: "content/agence/strategie.jpg" }) {
        childImageSharp {
          fluid(quality: 98, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       productionsImg: file(relativePath: { eq: "content/agence/productions.jpg" }) {
        childImageSharp {
          fluid(quality: 98, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       maisonImg: file(relativePath: { eq: "content/agence/maison.jpg" }) {
        childImageSharp {
          fluid(quality: 98, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       directionImg: file(relativePath: { eq: "content/agence/direction.jpg" }) {
        childImageSharp {
          fluid(quality: 98, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       allMarkdownRemark
       (
         filter: {
         fileAbsolutePath: {regex: "/(agence)/"  }}
         )
      {
         edges {
             node {
            frontmatter {
                instalife { 
                    childImageSharp {
                    fluid(quality: 95, maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                   }
                  }
                }
      } 
   }
}
}
`